import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../components/molecules/PageHeader';
import { Button } from '../../components/atoms/Button';
import { Description } from '../../components/atoms/Description';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './acceptOffer.scss';
import { handleChange, OfferFeatureFlag, setOfferStateToDefault } from '../../store/offerToolSlice';
import { extractOfferId } from '../../utils/fileUtils';
import { getBuildContentStatusOld } from '../../service/api';
import { BUILD_CONTENT_URL, BUILD_CONTENT_URL_OLD } from '../../config/constants';
import { store } from '../../store/store';
import { parseBoolean } from '../../utils/textUtils';

export const AcceptOffer: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isBuildContentEnabled, setIsBuildContentEnabled] = useState(false);
  const { dataServiceAssignee, offers, offersFeatureFlags } = store.getState().offerTool;
  const offerId = extractOfferId(location.search);
  const dotCMSFlag = offersFeatureFlags.find((el: OfferFeatureFlag) => el?.text === 'update-sot-cms');
  const newCMSEnabled = dotCMSFlag ? parseBoolean(dotCMSFlag.value) : false;
  const isPriveOrAmex = offers?.offerType === 'hyatt-prive-amex-luxury-consortia-discount-toolbox' || offers?.offerType === 'hyatt-prive';
  const isContentApprover = () => {
    if (
      !isPriveOrAmex &&
      dataServiceAssignee === 'Content Operations'
    ) {
      return true;
    }
    return false;
  };

  const getBuildContent = async () => {
    let buildContent;

    if (newCMSEnabled) {
      await new Promise(resolve => setTimeout(resolve, 5000));
      buildContent = true;
    } else {
      buildContent = await getBuildContentStatusOld(offerId);
    }

    if (buildContent) {
      setIsBuildContentEnabled(true);
    } else {
      setIsBuildContentEnabled(false);
    }
  };


  useEffect(() => {
    isContentApprover() && getBuildContent();
  }, [offerId]);

  useEffect(() => {
    window.utag_data.page_name = 'offertool-acceptoffer';
  }, []);

  const BuildContentButton = () => {
    return isBuildContentEnabled ? (
      <Button
        id="accept-offer-submit"
        dataAttribute="accept-offer-submit-button"
        label="Build Content"
        className="success-submit-button"
        primary
        isDisable={!isBuildContentEnabled}
        onClick={() => {
          if (newCMSEnabled) {
            window.open(BUILD_CONTENT_URL(offerId, offers.spiritCode.length > 1 ? 'global' : offers.spiritCode), '_blank');
          } else {
            window.open(BUILD_CONTENT_URL_OLD(offerId), '_blank');
          }
        }}
      />
    ) : (
      <Button
        id="accept-offer-build-content"
        dataAttribute="accept-offer-build-content-button"
        label=""
        className="success-submit-button loading-build-content-button"
        isloading
        loadingText="Building"
      />
    );
  };

  return (
    <section className="container accept-offer-container">
      <PageHeader
        title="Offer Task Approved"
        description="You have successfully approved this task."
      />
      <Description text="Details and status of this offer are available on your Dashboard." />
      <div className="btn-container">
        <Button
          id="accept-offer-go-to-dashboard"
          dataAttribute="accept-offer-go-to-dashboard-button"
          label="Go to Dashboard"
          onClick={() => {
            dispatch(handleChange({ name: 'page', value: 0 }));
            dispatch(setOfferStateToDefault());
            navigate('/');
          }}
        />
        {isContentApprover() && <BuildContentButton />}
      </div>
    </section>
  );
};
