import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ALL_OFFERS,
  BASE_URL,
  CREATE_PAGE_URL,
  CSRF_TOKEN,
  DELETE_OFFER,
  LOGIN_TOKEN,
  OFFER_BLACKOUT_RANGE_TYPES,
  OFFER_CANCELATION_POLICY_TYPES,
  OFFER_CATEGORIES_TYPES_URL,
  OFFER_DROPDOWN_TYPES,
  OFFER_FREQUENCY,
  OFFER_POLICY_TERM_TYPES,
  OFFER_RACK_DETAILS_TYPES,
  OFFER_TERMS_AND_CONDITIONS,
  HOTEL_OFFER,
  OFFER_ATTACHMENT_UPLOAD,
  OFFER_ATTACHMENT_DELETE,
  OFFER_SUBMIT,
  TASKS_DATA,
  UPDATE_OFFERS_PAGE_URL,
  SPIRITCODES,
  UPDATE_TASK,
  COMPLETE_TASK,
  GET_WORKFLOW,
  GET_ROUTES,
  GET_BACK_ROUTES,
  UPDATE_DATA_SERVICES,
  GET_DATA_SERVICE_COMMENTS,
  EXPORT_DAK,
  ENV,
  EXTEND_OFFER,
  END_OFFER,
  GET_STEP_BACK_OPTIONS,
  COPY_OFFER,
  APPROVAL_REPORT,
  EDIT_OFFER,
  FEATURE_LIST,
  HELP_TEXT,
  HBM_IMAGE_VALIDATION,
  GET_TOOLBOX_OFFER_CODE,
  OFFERS_FEATURE_FLAGS,
  BUILD_CONTENT_URL_OLD
} from '../config/constants';
import {
  AllOffersParam,
  CopyParams,
  DAKReportParams,
  DSSalesParams,
  DataServicesParams,
  ExtendOfferParam,
  FetchOffersParams,
} from '../store/offerToolSlice';
import { extractOfferCategory, extractOfferId, getRedirectPath } from '../utils/fileUtils';
import { ApprovalReportSearchParam } from '../pages/reporting';

interface OfferType {
  name: string;
  title: string;
}

const types: OfferType[] = [
  { name: 'htb-percent-off', title: 'HTB Percent Off' },
  { name: 'htb-rack-plus-attribute', title: 'HTB Rack + Attribute' },
  { name: 'hyatt-prive-amex-luxury-consortia-discount-toolbox', title:'HTB Privé-Amex-Luxury Consortia'},
  { name: 'woh-bonus-points-toolbox-offer', title:'WoH Bonus Points Toolbox Offer'}
];

const offerTypes: { [name: string]: string } = {};
types.forEach((item) => {
  offerTypes[item.name] = item.title;
});

export const getLoginToken = () => {
  const session = sessionStorage.getItem('offer-tool-authenticated-user');
  if (session) {
    try {
      const sessionObj = JSON.parse(session);
      return window.atob(sessionObj.login_token) as any;
    } catch (error) {
      redirectToAEM();
    }
  } else {
    redirectToAEM();
  }
};

export const getCSRFToken = async () => {
  try {
    const csrfToken = await (
      await fetch(CSRF_TOKEN, {
        method: 'GET',
        headers: {
          'login-token': getLoginToken(),
        },
      })
    ).json();
    if (Object.values(csrfToken).length === 0) {
      //token is empty
      redirectToAEM();
    } else {
      return csrfToken;
    }
  } catch (error) {
    console.log('CSRF Token error ', sessionStorage.getItem('offer-tool-authenticated-user'));
    redirectToAEM();
  }
};

export const createSpecialOffers = async (data: any = {}, uuid: string) => {
  try {
    const { spiritCode, promotionName, recordType } = data;
    const singleHotelTypeBody = `parentPath=/content/offers/hotels/${
      spiritCode[0]
    }&template=/apps/offerservice/templates/offerstool&./sling:resourceType=offerservice/components/structure/page&pageName=${uuid}&./jcr:title=${encodeURIComponent(
      promotionName
    )}&./status=Draft`;

    const chainHotelsTypeBody = `parentPath=/content/offers/hotels/global/&template=/apps/offerservice/templates/offerstool&./sling:resourceType=offerservice/components/structure/page&pageName=${uuid}&./jcr:title=${encodeURIComponent(
      promotionName
    )}&./status=Draft`;

    const csrfToken = await getCSRFToken();

    const response = await fetch(CREATE_PAGE_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'CSRF-token': csrfToken.token,
        'login-token': getLoginToken(),
      },
      body: recordType === 'Single' ? singleHotelTypeBody : chainHotelsTypeBody,
    });
    return response;
  } catch (error) {
    return {
      status: 500,
      statusText: 'Failed to create the offer',
    };
  }
};

export const createToolboxOffers = async (data: any = {}, uuid: string) => {
  try {
    const { recordType, spiritCode, offerType } = data;
    const SingleHotelbody = `parentPath=/content/offers/hotels/${
      spiritCode[0]
    }/toolbox&template=/apps/offerservice/templates/offerstool&./sling:resourceType=offerservice/components/structure/page&pageName=${uuid}&./jcr:title=${encodeURIComponent(
      offerTypes[offerType]
    )}&./status=Draft`;

    const chainHotelsBody = `parentPath=/content/offers/hotels/global/toolbox&template=/apps/offerservice/templates/offerstool&./sling:resourceType=offerservice/components/structure/page&pageName=${uuid}&./jcr:title=${encodeURIComponent(
      offerTypes[offerType]
    )}&./status=Draft`;

    const csrfToken = await getCSRFToken();
    const response = await fetch(CREATE_PAGE_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'CSRF-token': csrfToken.token,
        'login-token': getLoginToken(),
      },
      body: recordType === 'Single' ? SingleHotelbody : chainHotelsBody,
    });
    return response;
  } catch (error) {
    return {
      status: 500,
      statusText: 'Failed to create the offer',
    };
  }
};

export const deleteOffer = async (path: string) => {
  const body = `charset=UTF-8&force=true&checkChildren=true&archive=true&cmd=deletePage&path=${path}`;
  const csrfToken = await getCSRFToken();
  const response = await fetch(DELETE_OFFER, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'CSRF-token': csrfToken.token,
      'login-token': getLoginToken(),
    },
    body: body,
  });
  return response;
};

export const updateHotelOffersPage = async (
  data: any = {},
  uuid: string,
  path: string,
  offerId?: string
) => {
  const {
    offerCategory,
    offerType,
    promotionName,
    recordType,
    hotelName,
    additionalEmails,
    blackoutDates,
    inclusions,
    advancedBooking,
    cancellationAndCodes = {},
    bookingDates,
    offerValidity,
    pricing,
    distributionChannel,
    websiteOffers,
    termsAndConditions,
    spiritCode,
  } = data;
  let pagePath;

  const isPriveOffer = offerType === 'hyatt-prive';
  const isLuxuryConsortiaOffer = offerType === 'hyatt-luxury-consortia';

  const { bookingStartDate, bookingEndDate, stayStartDate, stayEndDate, updateDates } = bookingDates;
  const { maxStayNights, minStayNights, validDays } = offerValidity;
  const { qualifiers, offerRack, offerTypeValue, freeNightDetails, priveValueAdd, consortiaOptions } = pricing;

  const {
    communicationMethod,
    wholesaleConnected,
    wholesaleConnectedSpecificPartner,
    wholesaleNotConnected,
    wholesaleNotConnectedSpecificPartner,
    otaBundled,
    otaBundledSpecificPartner,
    luxuryConsortiaPartners,
    luxuryConsortiaPartnersSpecificPartner,
    gds,
    hyattCom,
    otaStandalone,
    voiceAndHotelDirect,
    priveWebsite,
  } = distributionChannel;

  const {
    isOffer,
    offerTitle,
    offerDescription,
    brandManagerID,
    materialsDueDate,
    translationRequired,
    languages,
  } = websiteOffers;

  const { closingText, mainText } = termsAndConditions;

  if (recordType === 'Chain') {
    pagePath = `/content/offers/hotels/global/${uuid}`;
  } else {
    pagePath = path ? path : `/content/offers/hotels/${spiritCode[0]}/${uuid}`;
  }
  const csrfToken = await getCSRFToken();

  const selectedHotels = hotelName;

  const emails =
    additionalEmails?.length > 0
      ? additionalEmails
          ?.map((email: any, index: number) => `./additionalEmails=${email}`)
          .join('&')
      : './additionalEmails@Delete';

  const sortedSpiritCodes = Array.from(spiritCode).sort();
  const spiritCodeArr = sortedSpiritCodes
    ?.map((code: any, index: number) => `./spiritCode=${code?.toLowerCase()}`)
    .join('&');

  const hotelArr = selectedHotels
    ?.map((hotel: any, index: number) => `./hotelName=${hotel}`)
    .join('&');
  
  const priveValueAddArr = priveValueAdd?.length > 0 ? priveValueAdd
    ?.map((options: any, index: number) => `./pricing/priveValueAdd=${options}`)
    .join('&') : './pricing/priveValueAdd@Delete';
  
  const consortiaOptionsArr = consortiaOptions
    ?.map((options: any, index: number) => `./pricing/consortiaOptions=${options}`)
    .join('&');

  let inclusionPart;
    inclusionPart =
      inclusions?.length > 0
        ? inclusions
            ?.map(
              ({ inclusionText, inclusionFrequency, inclusionDetails }: any, index: number) =>
                `./inclusions/item${index}/./inclusionText=${encodeURIComponent(
                  inclusionText
                )}&./inclusions/item${index}/./inclusionFrequency=${encodeURIComponent(
                  inclusionFrequency
                )}&./inclusions/item${index}/./inclusionDetails=${inclusionDetails ? encodeURIComponent(
                  inclusionDetails
                ): ''}`
            )
            .join('&')
        : './inclusions/item0/./inclusionText@Delete=&./inclusions/item0/./inclusionFrequency@Delete=&./inclusions/item0/./inclusionDetails@Delete=';

  const {
    bonusPointFrequency,
    advancedBookingEligibility,
    alcoholicBeverages,
    thirdPartyInclusions,
    advancedBookingDays,
    hyattBonusPoints,
    bonusPoints,
    maxBonusPoints,
  } = advancedBooking;
  const advancedBookingPart = `./advancedBooking/advancedBookingEligibility=${advancedBookingEligibility}&./advancedBooking/advancedBookingEligibility@Delete=&./advancedBooking/advancedBookingEligibility@Delete=&./advancedBooking/advancedBookingEligibility@TypeHint=Boolean&./advancedBooking/advancedBookingDays=${
    parseInt(advancedBookingDays) > 0 ? advancedBookingDays : ''
  }&./advancedBooking/advancedBookingDays@TypeHint=Long&./advancedBooking/thirdPartyInclusions@Delete=&./advancedBooking/thirdPartyInclusions@Delete=&./advancedBooking/thirdPartyInclusions=${
    thirdPartyInclusions ? thirdPartyInclusions : null
  }&./advancedBooking/thirdPartyInclusions@TypeHint=Boolean&./advancedBooking/alcoholicBeverages@Delete=&./advancedBooking/alcoholicBeverages@Delete=&./advancedBooking/alcoholicBeverages=${
    alcoholicBeverages ? alcoholicBeverages : null
  }&./advancedBooking/alcoholicBeverages@TypeHint=Boolean&./advancedBooking/hyattBonusPoints@Delete=&./advancedBooking/hyattBonusPoints@Delete=&./advancedBooking/hyattBonusPoints=${hyattBonusPoints}&./advancedBooking/hyattBonusPoints@TypeHint=Boolean&./advancedBooking/bonusPoints=${
    parseInt(bonusPoints) > 0 ? bonusPoints : ''
  }&./advancedBooking/bonusPoints@TypeHint=Long&./advancedBooking/maxBonusPoints=${
    parseInt(maxBonusPoints) > 0 ? maxBonusPoints : ''
  }&./advancedBooking/maxBonusPoints@TypeHint=Long&./advancedBooking/bonusPointFrequency=${
    bonusPointFrequency ? bonusPointFrequency : ''
  }&./advancedBooking/bonusPointFrequency@Delete=`;

  let { offerCodes, depositPolicy, penaltyPolicy, cancellationPolicy } = cancellationAndCodes;

  const offerCodesPart =
    offerCodes && offerCodes.length > 0
      ? offerCodes
          .map((offerCode: any) => `./cancellationAndCodes/offerCodes=${offerCode}`)
          .join('&')
      : './cancellationAndCodes/offerCodes=';

  const blackoutDatesPart =
    blackoutDates?.length > 0
      ? blackoutDates
          ?.map(
            ({ blackoutRange, blackoutStartDate, blackoutEndDate }: any, index: number) =>
              `./blackoutDates/item${index}/./blackoutRange=${blackoutRange}&./blackoutDates/item${index}/./blackoutStartDate=${blackoutStartDate}&./blackoutDates/item${index}/${
                blackoutEndDate ? `./blackoutEndDate=${blackoutEndDate}` : ''
              }`
          )
          .join('&')
      : '';

  const offerValidDays =
    validDays.length > 0
      ? validDays?.map((day: string) => `./offerValidity/validDays=${day.toLowerCase()}`).join('&')
      : '';

  const wholesaleConnectedSpecificPartnerValue = wholesaleConnectedSpecificPartner
    ?.map(
      (partner: string, index: number) =>
        `./distributionChannel/wholesaleConnectedSpecificPartner=${partner}`
    )
    .join('&');

  const wholesaleNotConnectedSpecificPartnerValue = wholesaleNotConnectedSpecificPartner
    ?.map(
      (partner: string, index: number) =>
        `./distributionChannel/wholesaleNotConnectedSpecificPartner=${partner}`
    )
    .join('&');

  const otaBundledSpecificPartnerValue = otaBundledSpecificPartner
    ?.map(
      (partner: string, index: number) =>
        `./distributionChannel/otaBundledSpecificPartner=${partner}`
    )
    .join('&');

  const luxuryConsortiaPartnersSpecificPartnerValue = luxuryConsortiaPartnersSpecificPartner
    ?.map(
      (partner: string, index: number) =>
        `./distributionChannel/luxuryConsortiaPartnersSpecificPartner=${partner}`
    )
    .join('&');
  const languagesArray = translationRequired
    ? languages
        ?.map((language: any, index: number) => `./websiteOffers/languages=${language}`)
        .join('&')
    : `./websiteOffers/languages@Delete`;
  const propertyWebsiteOffer = `./websiteOffers/includeInWebsite=${isOffer}&./websiteOffers/includeInWebsite@TypeHint=Boolean${
    isOffer
      ? `&./websiteOffers/offerTitle@Delete=&./websiteOffers/offerTitle=${encodeURIComponent(
          offerTitle
        )}&./websiteOffers/offerDescription@Delete=&./websiteOffers/offerDescription=${encodeURIComponent(
          offerDescription
        )}&./websiteOffers/brandManagerID@Delete=&./websiteOffers/brandManagerID=${encodeURIComponent(
          brandManagerID
        )}&./websiteOffers/translationRequired=${translationRequired}&./websiteOffers/translationRequired@TypeHint=Boolean&./websiteOffers/materialsDueDate@Delete=&./websiteOffers/materialsDueDate=${materialsDueDate}&./websiteOffers/changeOfferTitle@Delete=&./websiteOffers/changeOfferTitle=${isOffer}&${languagesArray}`
      : './websiteOffers/translationRequired@Delete=&./websiteOffers/changeOfferTitle@Delete=&./websiteOffers/offerTitle@Delete=&./websiteOffers/offerDescription@Delete=&./websiteOffers/brandManagerID@Delete=&./websiteOffers/materialsDueDate@Delete='
  }`;

  let hyattComParam: string = '';
  let voiceAndHotelDirectParam: string;
  let gdsParam: string;
  if(isPriveOffer){
    hyattComParam = priveWebsite ? `&./distributionChannel/distributionChannels=${encodeURIComponent('priveWebsite')}`
    : `&./distributionChannel/distributionChannels@Delete=`;    ;
    voiceAndHotelDirectParam = voiceAndHotelDirect
    ? `&./distributionChannel/distributionChannels=${encodeURIComponent('voiceAndHotel')}`
    : `&./distributionChannel/distributionChannels@Delete=`;
  gdsParam = gds
    ? `&./distributionChannel/distributionChannels=${encodeURIComponent('gds')}`
    : `&./distributionChannel/distributionChannels@Delete=`;
  }
  else if (isLuxuryConsortiaOffer) {
    hyattComParam = `&./distributionChannel/distributionChannels=${encodeURIComponent('priveWebsite')}`;
    voiceAndHotelDirectParam = `&./distributionChannel/distributionChannels=${encodeURIComponent(
      'voiceAndHotel'
    )}`;
    gdsParam = `&./distributionChannel/distributionChannels=${encodeURIComponent('gds')}`;
    depositPolicy = 'House Policy';
    cancellationPolicy = 'House Policy';
    penaltyPolicy = 'House Policy';
  } else {
    hyattComParam = hyattCom
      ? `&./distributionChannel/distributionChannels=${encodeURIComponent('hyatt')}`
      : `&./distributionChannel/distributionChannels@Delete=`;
    voiceAndHotelDirectParam = voiceAndHotelDirect
      ? `&./distributionChannel/distributionChannels=${encodeURIComponent('voiceAndHotel')}`
      : `&./distributionChannel/distributionChannels@Delete=`;
    gdsParam = gds
      ? `&./distributionChannel/distributionChannels=${encodeURIComponent('gds')}`
      : `&./distributionChannel/distributionChannels@Delete=`;
  }

  const otaStandaloneParam = otaStandalone
    ? `&./distributionChannel/distributionChannels=${encodeURIComponent('otaStandalone')}`
    : `&./distributionChannel/distributionChannels@Delete=`;

  const body = `_charset_=utf-8&./sling:resourceType=offerservice/components/offerstool/offers&./jcr:lastModified=&./jcr:lastModifiedBy=&${spiritCodeArr}&./offerId=${
    offerId ? offerId : uuid.substring(0, 6)
  }&./offerCategory=${offerCategory}&./offerType=${encodeURIComponent(
    offerType
  )}&./promotionName=${encodeURIComponent(
    promotionName
  )}&./recordType=${recordType}&./recordType@Delete=&${hotelArr}&./hotelName@Delete=&./additionalEmails@Delete=&${emails}&${inclusionPart}&./inclusions@Delete=&${advancedBookingPart}&./travelPartner/travelPartnerBonus@Delete=&./travelPartner/travelPartnerBonus@Delete=&./travelPartner/travelPartnerBonus@TypeHint=Boolean&./travelPartner/travelPartnerName=&./travelPartner/travelPartnerPoints=&./travelPartner/travelPartnerPoints@TypeHint=Long&./travelPartner/travelPartnerFrequency=perDay&./travelPartner/travelPartnerFrequency@Delete=&./travelPartner/travelPartnerMaxPointsEarned=&./travelPartner/travelPartnerMaxPointsEarned@TypeHint=Long&./bookingDates/bookingStartDate=${
    bookingStartDate ? bookingStartDate : ''
  }&./bookingDates/bookingStartDate@TypeHint=Date&./bookingDates/bookingEndDate=${
    bookingEndDate ? bookingEndDate : ''
  }&./bookingDates/updateDates@TypeHint=String&./bookingDates/updateDates=${
    updateDates ? updateDates : 'nooverride'
  }&./bookingDates/bookingEndDate@TypeHint=Date&./bookingDates/stayStartDate=${stayStartDate}&./bookingDates/stayStartDate@TypeHint=Date&./bookingDates/stayEndDate=${stayEndDate}&./bookingDates/stayEndDate@TypeHint=Date&./blackoutDates@Delete=(empty)&${
    blackoutDatesPart ? blackoutDatesPart : '(empty)'
  }&${offerValidDays}&./offerValidity/validDays@TypeHint=String&./offerValidity/minStayNights=${
    parseInt(minStayNights) > 0 ? minStayNights : ''
  }&./offerValidity/minStayNights@TypeHint=Long&./offerValidity/maxStayNights=${
    parseInt(maxStayNights) > 0 ? maxStayNights : ''
  }&./offerValidity/maxStayNights@TypeHint=Long&./pricing/offerRack=${offerRack}&./pricing/offerRack@Delete=&./pricing/offerTypeValue=${offerTypeValue}&./pricing/freeNightDetails=${freeNightDetails}&./pricing/offerTypeValue@TypeHint=Double&./pricing/qualifiers@Delete=&./pricing/qualifiers@Delete=&./pricing/qualifiers=${
    qualifiers
  }&./pricing/qualifiers@TypeHint=String&./pricing/priveValueAdd@Delete=&${priveValueAddArr}&./pricing/consortiaOptions@Delete=&${consortiaOptionsArr}&./cancellationAndCodes/offerCodes@Delete=&${offerCodesPart}&./cancellationAndCodes/depositPolicy=${encodeURIComponent(
    depositPolicy
  )}&./cancellationAndCodes/penaltyPolicy=${encodeURIComponent(
    penaltyPolicy
  )}&./cancellationAndCodes/cancellationPolicy=${cancellationPolicy}&./distributionChannel/communicationMethod@Delete=&./distributionChannel/communicationMethod@Delete=&./distributionChannel/communicationMethod=${
    communicationMethod ? communicationMethod : ''
  }&./distributionChannel/communicationMethod@TypeHint=String${hyattComParam.trim()}${voiceAndHotelDirectParam.trim()}${gdsParam.trim()}${otaStandaloneParam.trim()}&./distributionChannel/distributionChannels@TypeHint=String&./distributionChannel/wholesaleConnected@Delete=&./distributionChannel/wholesaleConnected=${
    wholesaleConnected && 'wsConnected'
  }&${
    wholesaleConnectedSpecificPartnerValue
      ? wholesaleConnectedSpecificPartnerValue
      : './distributionChannel/wholesaleConnectedSpecificPartner@Delete'
  }&./distributionChannel/wholesaleNotConnected@Delete=&./distributionChannel/wholesaleNotConnected=${
    wholesaleNotConnected && 'wsNotConnected'
  }&${
    wholesaleNotConnectedSpecificPartnerValue
      ? wholesaleNotConnectedSpecificPartnerValue
      : './distributionChannel/wholesaleNotConnectedSpecificPartner@Delete'
  }&./distributionChannel/otaBundled@Delete=&./distributionChannel/otaBundled=${
    otaBundled && 'bundled'
  }&${
    otaBundledSpecificPartnerValue
      ? otaBundledSpecificPartnerValue
      : './distributionChannel/otaBundledSpecificPartner@Delete'
  }&./distributionChannel/luxuryConsortiaPartners@Delete=&./distributionChannel/luxuryConsortiaPartners=${
    luxuryConsortiaPartners && 'luxuryConsortia'
  }&./distributionChannel/luxuryConsortiaPartnersSpecificPartner@Delete=&${
    luxuryConsortiaPartnersSpecificPartnerValue
      ? luxuryConsortiaPartnersSpecificPartnerValue
      : './distributionChannel/luxuryConsortiaPartnersSpecificPartner@Delete'
  }&./termsAndConditions/mainText=${
    mainText !== 'undefined' ? encodeURIComponent(mainText) : ''
  }&./termsAndConditions/closingText=${
    closingText !== 'undefined' ? encodeURIComponent(closingText) : ''
  }&./htbPercent/inclusions@Delete=&./bookingLeadTime/noOfDays=&${propertyWebsiteOffer}&:cq_csrf_token=${
    csrfToken.token
  }`;

  const response = await fetch(UPDATE_OFFERS_PAGE_URL(pagePath), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'CSRF-token': csrfToken.token,
      'login-token': getLoginToken(),
    },
    body: body,
  });

  return response;
};

export const updateToolboxOffersPage = async (
  data: any = {},
  uuid: string,
  path: string,
  offerId?: string
) => {
  const csrfToken = await getCSRFToken();
  const {
    offerCategory,
    offerType,
    spiritCode,
    recordType,
    hotelName,
    additionalEmails,
    inclusions,
    pricing,
    cancellationAndCodes = {},
    bookingDates,
    bookingDayLeadTime,
    websiteOffers,
    termsAndConditions,
    offerValidity,
  } = data;

  let pagePath;
  if (recordType === 'Chain') {
    pagePath = `/content/offers/hotels/global/toolbox/${uuid}`;
  } else {
    pagePath = path ? path : `/content/offers/hotels/${spiritCode[0]}/toolbox/${uuid}`;
  }

  const selectedHotels = hotelName;

  const sortedSpiritCodes = Array.from(spiritCode).sort();
  const spiritCodeArr = sortedSpiritCodes
    ?.map((code: any, index: number) => `./spiritCode=${code?.toLowerCase()}`)
    .join('&');

  const hotelArr = selectedHotels
    ?.map((hotel: any, index: number) => `./hotelName=${hotel}`)
    .join('&');

  const { bookingStartDate, bookingEndDate, stayStartDate, stayEndDate, updateDates } = bookingDates;

  const { minStayNights, maxStayNights } = offerValidity;

  //instead of htb we have to use this object till aem changes are made.
  const { qualifiers, offerRack } = pricing;
  const {
    isOffer,
    offerTitle,
    offerDescription,
    changeOfferTitle,
    materialsDueDate,
    translationRequired,
    languages,
    brandManagerID,
  } = websiteOffers;
  const { closingText, mainText } = termsAndConditions;

  const emails =
    additionalEmails?.length > 0
      ? additionalEmails
          ?.map((email: any, index: number) => `./additionalEmails=${email}`)
          .join('&')
      : './additionalEmails@Delete';

  const inclusionPart = inclusions?.length > 0
    ? inclusions?.map(
      (inclusion: any, index: number) =>
        `./inclusions/item${index}/./inclusionText=${encodeURIComponent(
          inclusion?.inclusionText
        )}&./inclusions/item${index}/./inclusionDetails=${encodeURIComponent(
          inclusion.inclusionDetails
        )}`
    )
    .join('&')
    : './inclusions/item0/./inclusionText@Delete=&./inclusions/item0/./inclusionDetails@Delete=';

  const { offerCodes, depositPolicy, penaltyPolicy, cancellationPolicy } = cancellationAndCodes;

  const offerCodesPart =
    offerCodes && offerCodes.length > 0
      ? offerCodes
          ?.map((offerCode: any) => `./cancellationAndCodes/offerCodes=${offerCode}`)
          .join('&')
      : './cancellationAndCodes/offerCodes=';
  const languagesArray = translationRequired
    ? languages
        ?.map((language: any, index: number) => `./websiteOffers/languages=${language}`)
        .join('&')
    : `./websiteOffers/languages@Delete`; 
  const propertyWebsiteOffer = `./websiteOffers/includeInWebsite=${isOffer}&./websiteOffers/includeInWebsite@TypeHint=Boolean&${
    isOffer
      ? `./websiteOffers/offerTitle@Delete=&./websiteOffers/offerTitle=${encodeURIComponent(
          changeOfferTitle && offerTitle ? offerTitle : ''
        )}&./websiteOffers/offerDescription@Delete=&./websiteOffers/offerDescription=${encodeURIComponent(
          offerDescription ? offerDescription : ''
        )}&./websiteOffers/brandManagerID@Delete=&./websiteOffers/brandManagerID=${encodeURIComponent(
          brandManagerID ? brandManagerID : ''
        )}&./websiteOffers/materialsDueDate@Delete=&./websiteOffers/materialsDueDate=${
          materialsDueDate ? materialsDueDate : ''
        }&./websiteOffers/translationRequired@TypeHint=Boolean&./websiteOffers/translationRequired=${translationRequired}&./websiteOffers/changeOfferTitle@Delete=&./websiteOffers/changeOfferTitle@TypeHint=Boolean&./websiteOffers/changeOfferTitle=${
          changeOfferTitle ? changeOfferTitle : false
        }&${languagesArray}`
      : ''
  }`;

  const body = `_charset_=utf-8&./sling:resourceType=offerservice/components/offerstool/offers&./jcr:lastModified=&./jcr:lastModifiedBy=&${spiritCodeArr}&./offerId=${
    offerId ? offerId : uuid.substring(0, 6)
  }&./offerCategory=${offerCategory}&./offerType=${encodeURIComponent(
    offerType
  )}&./promotionName=${encodeURIComponent(
    offerTypes[offerType]
  )}&./recordType=${recordType}&./recordType@Delete=&${hotelArr}&./hotelName@Delete=&./additionalEmails@Delete=&${emails}&./inclusions@Delete=&${inclusionPart}&./bookingDates/bookingStartDate=${
    bookingStartDate ? bookingStartDate : ''
  }&./bookingDates/bookingStartDate@TypeHint=Date&./bookingDates/bookingEndDate=${
    bookingEndDate ? bookingEndDate : ''
  }&./bookingDates/updateDates@TypeHint=String&./bookingDates/updateDates=${
    updateDates ? updateDates : 'nooverride'
  }&./bookingDates/bookingEndDate@TypeHint=Date&./bookingDates/stayStartDate=${stayStartDate}&./bookingDates/stayStartDate@TypeHint=Date&./bookingDates/stayEndDate=${stayEndDate}&./bookingDates/stayEndDate@TypeHint=Date&./pricing/qualifiers@TypeHint=String&${offerCodesPart}&./pricing/bonusPoints@TypeHint=String&./cancellationAndCodes/offerCodes@Delete=&./cancellationAndCodes/depositPolicy=${encodeURIComponent(
    depositPolicy
  )}&./cancellationAndCodes/penaltyPolicy=${encodeURIComponent(
    penaltyPolicy
  )}&./cancellationAndCodes/cancellationPolicy=${cancellationPolicy}&./termsAndConditions/mainText=${
    mainText !== 'undefined' ? encodeURIComponent(mainText) : ''
  }&./termsAndConditions/closingText=${
    closingText !== 'undefined' ? encodeURIComponent(closingText) : ''
  }&./pricing/offerRack=${encodeURIComponent(offerRack)}&./offerValidity/minStayNights=${
    parseInt(minStayNights) > 0 ? minStayNights : ''
  }&./pricing/offerRack=${encodeURIComponent(offerRack)}&./offerValidity/maxStayNights=${
    parseInt(maxStayNights) > 0 ? maxStayNights : ''
  }&./pricing/offerRack@TypeHint=String&./pricing/eligibility@Delete=&./pricing/eligibility=${qualifiers}&./pricing/bonusPoints=${pricing.bonusPoints}&./pricing/eligibility@TypeHint=String&./advancedBooking/advancedBookingDays=${bookingDayLeadTime}&${propertyWebsiteOffer}&:cq_csrf_token=${
    csrfToken.token
  }`;

  const response = await fetch(UPDATE_OFFERS_PAGE_URL(pagePath), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'CSRF-token': csrfToken.token,
      'login-token': getLoginToken(),
    },
    body: body,
  });

  return response;
};

export const authenticateUser = async (token: string) => {
  const loginToken = await (
    await fetch(LOGIN_TOKEN, {
      method: 'GET',
      headers: {
        'login-token': token,
      },
    })
  ).json();

  if (loginToken) {
    return loginToken;
  }
};

export const redirectToAEM = () => {
  const redirectPath = getRedirectPath();
  const isRoot = redirectPath?.includes('offerPath=');
  let redirectUrl = `/`;
  if (redirectPath) {
    redirectUrl = redirectPath + encodeURIComponent(isRoot ? '&' : '');
  }
  const urlWithoutDomain = redirectUrl.replace(/^https?:\/\/[^\/]+/, '');
  let isLocal = false;
  if (!ENV) {
    isLocal = true;
  }
  const fragment = urlWithoutDomain === '/' ? '' : `fragment=${urlWithoutDomain}`;
  window.location.replace(
    `${BASE_URL}/bin/logincallback?${isLocal ? `local=true&${fragment}` : fragment}`
  );
};

export const getTotalOfferCount = createAsyncThunk('totalOffers', async (categroy: string) => {
  await getCSRFToken();
  const url = `${ALL_OFFERS}`;
  return await (
    await fetch(url, {
      headers: {
        'login-token': getLoginToken(),
      },
    })
  ).json();
});

export const getFeatureList = createAsyncThunk('getFeatureLists', async () => {
  await getCSRFToken();
  const url = `${FEATURE_LIST}`;
  return await (
    await fetch(url, {
      headers: {
        'login-token': getLoginToken(),
      },
    })
  ).json();
});

export const getFeatureFlags = createAsyncThunk('getFeatureFlags', async () => {
  await getCSRFToken();

  const headers = new Headers({
    'login-token': getLoginToken()
  });

  const fetchWithAuth = (url: string) => fetch(url, { headers } ).then(res => res.json());

  const flagsData = [fetchWithAuth(FEATURE_LIST), fetchWithAuth(OFFERS_FEATURE_FLAGS)]

  const [featureListFlags, offersFeatureFlags] = await Promise.all(flagsData);

  return {
    featureListFlags,
    offersFeatureFlags
  };
});

export const getTotalSubmittedOfferCount = createAsyncThunk(
  'totalSubmittedOffers',
  async (_categroy: string) => {
    await getCSRFToken();
    const url = `${ALL_OFFERS}?status=Submitted`;
    return await (
      await fetch(url, {
        headers: {
          'login-token': getLoginToken(),
        },
      })
    ).json();
  }
);

export const getTotalRejectedOfferCount = createAsyncThunk(
  'totalRejectedOffers',
  async (_categroy: string) => {
    await getCSRFToken();
    const url = `${ALL_OFFERS}?status=Rejected`;
    return await (
      await fetch(url, {
        headers: {
          'login-token': getLoginToken(),
        },
      })
    ).json();
  }
);

export const getTotalApprovedOfferCount = createAsyncThunk(
  'totalApprovedOffers',
  async (_categroy: string) => {
    await getCSRFToken();
    const url = `${ALL_OFFERS}?status=Approved`;
    return await (
      await fetch(url, {
        headers: {
          'login-token': getLoginToken(),
        },
      })
    ).json();
  }
);

export const getTotalDraftsOfferCount = createAsyncThunk(
  'totalDrafts',
  async (_categroy: string) => {
    await getCSRFToken();
    const url = `${ALL_OFFERS}?status=Draft`;
    return await (
      await fetch(url, {
        headers: {
          'login-token': getLoginToken(),
        },
      })
    ).json();
  }
);

export const getInclutionTypes = createAsyncThunk('inclutionTypes', async () => {
  await getCSRFToken();
  return await (
    await fetch(`${OFFER_FREQUENCY}`, {
      headers: {
        'login-token': getLoginToken(),
      },
    })
  ).json();
});

export const getOfferFrequency = createAsyncThunk('frequencyOptions', async () => {
  await getCSRFToken();
  return await (
    await fetch(`${OFFER_FREQUENCY}`, {
      headers: {
        'login-token': getLoginToken(),
      },
    })
  ).json();
});

export const getOfferBlackoutRange = createAsyncThunk('blackoutRangeOptions', async () => {
  await getCSRFToken();
  return await (
    await fetch(`${OFFER_BLACKOUT_RANGE_TYPES}`, {
      headers: {
        'login-token': getLoginToken(),
      },
    })
  ).json();
});

export const getOfferCancellationPolicies = createAsyncThunk(
  'offerCacellationPolicies',
  async () => {
    await getCSRFToken();
    return await (
      await fetch(`${OFFER_CANCELATION_POLICY_TYPES}`, {
        headers: {
          'login-token': getLoginToken(),
        },
      })
    ).json();
  }
);

export const getOfferPolicyTerm = createAsyncThunk('offerCancelationPolicyTerm', async () => {
  await getCSRFToken();
  return await (
    await fetch(`${OFFER_POLICY_TERM_TYPES}`, {
      headers: {
        'login-token': getLoginToken(),
      },
    })
  ).json();
});

export const getPricingRackDetails = createAsyncThunk('offerPricingRackDetails', async () => {
  await getCSRFToken();
  return await (
    await fetch(`${OFFER_RACK_DETAILS_TYPES}`, {
      headers: {
        'login-token': getLoginToken(),
      },
    })
  ).json();
});

export const getOfferCategoriesAndTypes = createAsyncThunk('offerCategoriesAndTypes', async () => {
  await getCSRFToken();
  const resp = await fetch(OFFER_CATEGORIES_TYPES_URL, {
    headers: {
      'login-token': getLoginToken(),
    },
  });
  const data = await resp.json();

  return data;
});

export const getHotelOffer = createAsyncThunk('offers', async ({ path }: OfferParams) => {
  await getCSRFToken();
  const url = `${HOTEL_OFFER(path)}`;
  return await (
    await fetch(url, {
      headers: {
        'login-token': getLoginToken(),
      },
    })
  ).json();
});

export const getToolboxOffer = createAsyncThunk('toolbox-offers', async ({ path }: OfferParams) => {
  await getCSRFToken();
  const url = `${HOTEL_OFFER(path)}`;
  return await (
      await fetch(url, {
      headers: {
        'login-token': getLoginToken(),
      },
    })
  ).json();
});

export const getTasks = createAsyncThunk('allTasks', async () => {
  await getCSRFToken();
  return await (
    await fetch(`${TASKS_DATA}`, {
      headers: {
        'login-token': getLoginToken(),
      },
    })
  ).json();
});

export const getAllOffers = createAsyncThunk<AllOffersParam, FetchOffersParams>(
  'allOffers',
  async (params = { offset: 0, offerCategory: 'hotel-special-offer' }) => {
    await getCSRFToken();
    let param = '';
    if (params.status && params.status !== 'All') {
      param = `status=${params.status}&`;
    }
    if (params.offerType) {
      param += `offerType=${params.offerType}&`;
    }
    if (params.startDate) {
      param += `startDate=${params.startDate}&`;
    }
    if (params.endDate) {
      param += `endDate=${params.endDate}&`;
    }
    if (params.searchText) {
      param += 'fulltext=' + encodeURIComponent(params.searchText) + '&';
    }
    return await (
      await fetch(
        `${ALL_OFFERS}?${param}offset=${params.offset}&limit=25${
          params.sortBy ? '&sortBy=' + params.sortBy + '&' : ''
        }${params.sortOrder ? 'sortOrder=' + params.sortOrder : ''}${
          params.offerCategory !== 'all' ? '&offerCategory=' + params.offerCategory : ''
        }`,
        {
          headers: {
            'login-token': getLoginToken(),
          },
        }
      )
    ).json();
  }
);

export const getPricingDropdown = createAsyncThunk(
  'pricingDropdown',
  async (arg: string, thunkAPI) => {
    await getCSRFToken();
    return await (
      await fetch(`${OFFER_DROPDOWN_TYPES(arg)}`, {
        headers: {
          'login-token': getLoginToken(),
        },
      })
    ).json();
  }
);

interface OfferParams {
  path: string;
}

export const uploadOfferAttachment = async (
  data: File,
  attachmentType: string,
  offerPath: string
) => {
  const formData = new FormData();
  formData.append('offerPath', offerPath);
  formData.append('attachmentType', attachmentType);
  formData.append('file', data);

  const csrfToken = await getCSRFToken();
  const response = await fetch(OFFER_ATTACHMENT_UPLOAD, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'CSRF-token': csrfToken.token,
      'login-token': getLoginToken(),
    },
    body: formData,
  });
  return {
    data: response.status === 201 ? await response.json() : {},
    status: response.status,
  };
};

export const uploadMultipleOfferAttachments = async ({ files, offerPath, attachmentType }: { files: FileList, offerPath: string, attachmentType: string }) => {
  const formData = new FormData();
  formData.append('offerPath', offerPath);
  formData.append('attachmentType', attachmentType);

  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    formData.append(file.name, file);
  }

  const csrfToken = await getCSRFToken();
  const response = await fetch(OFFER_ATTACHMENT_UPLOAD, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'CSRF-token': csrfToken.token,
      'login-token': getLoginToken(),
    },
    body: formData,
  });
  
  return {
    data: response.status === 201 ? await response.json() : {},
    status: response.status,
  };
}

export const deleteOfferAttachment = async (filePath: string) => {
  const csrfToken = await getCSRFToken();
  const deleteUrl = `${OFFER_ATTACHMENT_DELETE}?filePath=${encodeURIComponent(filePath)}`;

  const response = await fetch(deleteUrl, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'CSRF-token': csrfToken.token,
      'login-token': getLoginToken(),
    },
  });
  return {
    status: response.status,
  };
};

export const getTermsAndConditions = createAsyncThunk(
  'termsAndConditions',
  async (offerPath: string) => {
    await getCSRFToken();
    const resp = await (
      await fetch(OFFER_TERMS_AND_CONDITIONS(offerPath), {
        headers: {
          'login-token': getLoginToken(),
        },
      })
    ).json();
    return resp;
  }
);

export const getComments = createAsyncThunk('comments', async (offerPath: string) => {
  await getCSRFToken();
  return await (
    await fetch(GET_DATA_SERVICE_COMMENTS(offerPath), {
      headers: {
        'login-token': getLoginToken(),
      },
    })
  ).json();
});

export const submitOffer = async (offerPath: string) => {
  const body = `_charset_=utf-8&payloadType=JCR_PATH&model=/var/workflow/models/offersapproval&workflowTitle=OffersApproval2&payload=${offerPath}`;
  const csrfToken = await getCSRFToken();
  const response = await fetch(OFFER_SUBMIT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'CSRF-token': csrfToken.token,
      'login-token': getLoginToken(),
    },
    body: body,
  });
  return response;
};

export const getSpiritCode = createAsyncThunk('allHotelNames', async () => {
  await getCSRFToken();
  return await (
    await fetch(SPIRITCODES, {
      headers: {
        'login-token': getLoginToken(),
      },
    })
  ).json();
});

export const imageValidation = async (imageName: string) => {
  const validationUrl = HBM_IMAGE_VALIDATION + '?query=' + imageName + '&mimeType=jpeg&lowerLimit=0&upperLimit=10'
  return await (
    await fetch(validationUrl, {
      headers: {
        'login-token': getLoginToken(),
      },
    })
  ).json();
}

export const getRoutes = async (workflowId: string, option: 'accept' | 'reject') => {
  const workflowRoutes = await (
    await fetch(GET_ROUTES(workflowId), {
      headers: {
        'login-token': getLoginToken(),
      },
    })
  ).json();
  if (option === 'accept') {
    return workflowRoutes.routes[0].rid;
  } else {
    return workflowRoutes.routes[1].rid;
  }
};

export const getBackRoutes = async (workflowItemId: string, offerPath: string) => {
  const workflowId = workflowItemId ? workflowItemId : await getWorkflow(offerPath);
  const workflowBackRoutes = await (
    await fetch(GET_BACK_ROUTES(workflowId), {
      headers: {
        'login-token': getLoginToken(),
      },
    })
  ).json();
  return workflowBackRoutes;
};

export const getStepBackOptions = async (workflowId: string) => {
  await getCSRFToken();
  return await (
    await fetch(GET_STEP_BACK_OPTIONS(workflowId), {
      headers: {
        'login-token': getLoginToken(),
      },
    })
  ).json();
};

export const updateTask = async (
  data: any = {},
  offerPath: string,
  dataService: DataServicesParams,
  dataServiceNotes: string,
  dataServiceActionReason: string,
  option: 'accept' | 'reject',
  dataServiceAssignee: string,
  workflowItemId: string,
  path: string
) => {
  const pathToUse = path ? path : offerPath;
  const encodedDataServiceActionReason = dataServiceActionReason.replace(/%/g, "$#@!")
    .replace(/&/g, "^$@*")
    .replace(/\+/g, "@!$~");
  try {
    encodedDataServiceActionReason !== '' &&
      (await updateTaskContent(
        pathToUse,
        option === 'accept'
          ? `accept/assignee:${dataServiceAssignee}/` + encodedDataServiceActionReason
          : `reject/assignee:${dataServiceAssignee}/` + encodedDataServiceActionReason
      ));
    option === 'accept' && (await updateDataServices(data, pathToUse, dataService, pathToUse));
    dataServiceNotes !== '' && (await updateTaskContent(pathToUse, 'DSNotes/' + dataServiceNotes));
    const workflowId = workflowItemId ? workflowItemId : await getWorkflow(pathToUse);
    const workflowRoutes = await getRoutes(workflowId, option);
    await completeTheTask(workflowId, workflowRoutes);
    return '1';
  } catch (error) {
    return '0';
  }
};

export const saveTask = async (
  data: any = {},
  offerPath: string,
  dataService: DataServicesParams,
  dataServiceNotes: string,
  path: string
) => {
  try {
    await getCSRFToken();
    await updateDataServices(data, offerPath, dataService, path);
    dataServiceNotes !== '' && (await updateTaskContent(offerPath, dataServiceNotes));
    return true;
  } catch (error) {
    return false;
  }
};

export const updateTaskContent = async (offerPath: string, notes: string) => {
  const body = `:operation=granite:comment&path=${offerPath}&message=${notes}&_charset_=utf-8`;
  const csrfToken = await getCSRFToken();
  const response = await fetch(UPDATE_TASK(offerPath), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'CSRF-token': csrfToken.token,
      'login-token': getLoginToken(),
    },
    body: body,
  });
  return response;
};

export const updateDataServices = async (
  data: any = {},
  offerPath: string,
  dataService: DataServicesParams,
  path: string
) => {
  if (extractOfferCategory(offerPath) === 'hotel-special-offer') {
    await updateHotelOffersPage(data, extractOfferId(offerPath), path, data.offerId);
  } else {
    await updateToolboxOffersPage(data, extractOfferId(offerPath), path, data.offerId);
  }

  const directSells = dataService.directSell
    ?.map(
      (ds: DSSalesParams, index: number) =>
        `./directSell/item${index}/./market=${ds.market}&./directSell/item${index}/./category=${ds.category}&./directSell/item${index}/./ratePlanCode=${ds.ratePlanCode}`
    )
    .join('&');

  const otherRatePlans = dataService.otherRatePlans
    ?.map(
      (ds: DSSalesParams, index: number) =>
        `./otherRatePlans/item${index}/./market=${ds.market}&./otherRatePlans/item${index}/./category=${ds.category}&./otherRatePlans/item${index}/./ratePlanCode=${ds.ratePlanCode}`
    )
    .join('&');

  const body = `./crNumber@Delete=&./crNumber=${
    dataService.crNumber ? dataService.crNumber : ''
  }&./directSell@Delete=&${directSells}&./otherRatePlans@Delete=&${otherRatePlans}
  &./dsAssociateTime@Delete=&./dsAssociateTime=${
    dataService.dsAssociateTime ? dataService.dsAssociateTime : ''
  }&./dsMarketingTime@Delete=&./dsMarketingTime=${
    dataService.dsMarketingTime ? dataService.dsMarketingTime : ''
  }`;

  const csrfToken = await getCSRFToken();
  const response = await fetch(UPDATE_DATA_SERVICES(offerPath), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'CSRF-token': csrfToken.token,
      'login-token': getLoginToken(),
    },
    body: body,
  });

  return response;
};

export const getWorkflow = async (offerPath: string) => {
  const workflow = await (
    await fetch(GET_WORKFLOW(offerPath), {
      headers: {
        'login-token': getLoginToken(),
      },
    })
  ).json();
  const workflowLength = Object.keys(workflow).length;
  return workflow[workflowLength - 1].workItemId;
};

export const completeTheTask = async (workflowId: any, workflowRoutes: any) => {
  const body = `cmd=advance&:status=browser&_charset_=utf-8&item=${workflowId}&route-${workflowId}=${workflowRoutes}`;
  const csrfToken = await getCSRFToken();
  const response = await fetch(COMPLETE_TASK, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'CSRF-token': csrfToken.token,
      'login-token': getLoginToken(),
    },
    body: body,
  });
  return response;
};

export const getDataSerivceComments = createAsyncThunk(
  'dataServiceComments',
  async (offerPath: string) => {
    await getCSRFToken();
    return await (
      await fetch(GET_DATA_SERVICE_COMMENTS(offerPath), {
        headers: {
          'login-token': getLoginToken(),
        },
      })
    ).json();
  }
);

export const getWorkItemId = createAsyncThunk('getWorkItemId', async (offerPath: string) => {
  await getCSRFToken();
  return await (
    await fetch(GET_WORKFLOW(offerPath), {
      headers: {
        'login-token': getLoginToken(),
      },
    })
  ).json();
});

export const exportDAKReport = async (params: DAKReportParams) => {
  await getCSRFToken();
  const dakResponse = await fetch(
    `${EXPORT_DAK}?createdFrom=${params.createdFrom}&createdTo=${params.createdTo}&submittedFrom=${params.submittedFrom}&submittedTo=${params.submittedTo}`,
    {
      headers: {
        'login-token': getLoginToken(),
      },
    }
  );
  if (dakResponse.status === 200) {
    const blob = await dakResponse.blob();    
    if (blob.type === 'application/vnd.ms-excel') {
      const downloadUrl = window.URL.createObjectURL(blob);
      return {
        isError: false,
        messsage: downloadUrl,
      };
    } else {
      return {
        isError: true,
        messsage: 'Please update your search criteria and try again',
      };
    }
  } else {
    return {
      isError: true,
      messsage: 'Failed to fetch the report. please try again',
    };
  }
};

export const dataServiceStepBack = async (
  offerPath: string,
  dataServiceStepBackReason: string,
  workflowItemId: string,
  stepBackAssigneeId: string,
  dataServiceAssignee: string,
) => {
  try {
    await updateTaskContent(offerPath, `stepback/assignee:${dataServiceAssignee}/` + dataServiceStepBackReason);
    const workflowId = workflowItemId ? workflowItemId : await getWorkflow(offerPath);
    await stepBack(workflowId, stepBackAssigneeId!);
    return '1';
  } catch (error) {
    return '0';
  }
};

export const stepBack = async (workflowId: string, stepBackAssigneeId: string) => {
  const body = `cmd=advanceBack&:status=browser&_charset_=utf-8&item=${encodeURIComponent(
    workflowId
  )}&backroute-${encodeURIComponent(workflowId)}=${encodeURIComponent(stepBackAssigneeId)}`;

  const csrfToken = await getCSRFToken();
  const response = await fetch(COMPLETE_TASK, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'CSRF-token': csrfToken.token,
      'login-token': getLoginToken(),
      'x-requested-with': 'XMLHttpRequest',
    },
    body: body,
  });
  return response;
};

export const extendCurrentyOffer = async ({
  bookingEndDate,
  stayEndDate,
  offerPath,
}: ExtendOfferParam) => {
  const body: ExtendOfferParam = {
    bookingEndDate: bookingEndDate,
    stayEndDate: stayEndDate,
  };

  if (offerPath) {
    const csrfToken = await getCSRFToken();
    const response = await fetch(EXTEND_OFFER(offerPath), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'CSRF-token': csrfToken.token,
        'login-token': getLoginToken(),
      },
      body: JSON.stringify(body),
    });
    return response;
  } else {
    return {
      status: 400,
      body: 'Invalid request!',
    };
  }
};

export const endCurrentyOffer = async ({
  bookingEndDate,
  stayEndDate,
  offerPath,
}: ExtendOfferParam) => {
  const body: ExtendOfferParam = {
    bookingEndDate: bookingEndDate,
    stayEndDate: stayEndDate,
  };

  if (offerPath) {
    const csrfToken = await getCSRFToken();
    const response = await fetch(END_OFFER(offerPath), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'CSRF-token': csrfToken.token,
        'login-token': getLoginToken(),
      },
      body: JSON.stringify(body),
    });
    return response;
  } else {
    return {
      status: 400,
      body: 'Invalid request!',
    };
  }
};

export const copyOffer = async ({
  sourcePath,
  destinationPath,
  newOfferName,
  additionalEmails,
  spiritCodes,
  hotelNames,
  promotionName,
}: CopyParams) => {
  if (sourcePath && destinationPath && newOfferName) {
    const body: CopyParams = {
      sourcePath: sourcePath,
      destinationPath: destinationPath,
      newOfferName: newOfferName,
      additionalEmails: additionalEmails,
      spiritCodes: spiritCodes,
      hotelNames: hotelNames,
      promotionName: promotionName,
    };
    const csrfToken = await getCSRFToken();
    return await fetch(COPY_OFFER(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'CSRF-token': csrfToken.token,
        'login-token': getLoginToken(),
      },
      body: JSON.stringify(body),
    });
  } else {
    return {
      status: 400,
      body: 'Invalid request!',
    };
  }
};

export const getBuildContentStatusOld = async (uuid: string) => {
  const buildContent =
    await fetch(BUILD_CONTENT_URL_OLD(uuid), {
      headers: {
        'login-token': getLoginToken(),
      },
    });  
  if (buildContent.status === 200) {
    return true;
  } else {
    return false;
  }
};
export const fetchAssignee = async (offerId: string) => {
  const resp = await (
    await fetch(`${ALL_OFFERS}?fulltext=${offerId}&limit=1`, {
      headers: {
        'login-token': getLoginToken(),
      },
    })
  ).json();
  if (resp.offers[0].assignee) {    
    return resp.offers[0].assignee;
  } else {
    return undefined;
  }
}

export const exportApprovalReport = async (params: ApprovalReportSearchParam | undefined) => {
  await getCSRFToken();

  let searchParams = '';
  if (params) {
    if (params.fromDate) {
      searchParams = `fromDate=${params.fromDate}&`;
    }
    if (params.toDate) {
      searchParams += `toDate=${params.toDate}&`;
    }
    if (params.noOfDays) {
      searchParams = `days=${params.noOfDays}`;
    }
  }

  const reportResponse = await fetch(`${APPROVAL_REPORT}?${searchParams}`, {
    headers: {
      'login-token': getLoginToken(),
    },
  });

  if (reportResponse.status === 200) {
    const blob = await reportResponse.blob();
    if (blob.type === 'text/csv') {
      const downloadUrl = window.URL.createObjectURL(blob);
      return {
        isError: false,
        messsage: downloadUrl,
      };
    } else {
      return {
        isError: true,
        messsage: 'Please update your search criteria and try again',
      };
    }
  } else {
    return {
      isError: true,
      messsage: 'Failed to fetch the report. please try again',
    };
  }
};

export const editOffer = async (offerPath?: string) => {
  if (offerPath) {
    const csrfToken = await getCSRFToken();
    const response = await fetch(EDIT_OFFER(offerPath), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'CSRF-token': csrfToken.token,
        'login-token': getLoginToken(),
      },
    });
    if (response.status === 201) {
      const blob = await response.blob();
      const launchPath = await blob.text();
      return {
        status: 201,
        body: launchPath,
      };
    } else {
      return {
        status: 500,
        body: 'Request failed. please try again',
      };
    }
  } else {
    return {
      status: 400,
      body: 'Invalid request!',
    };
  }
};

export const getHelpText = createAsyncThunk('helpTexts', async () => {
  await getCSRFToken();
  return await (
    await fetch(HELP_TEXT, {
      headers: {
        'login-token': getLoginToken(),
      },
    })
  ).json();
});

export const getToolboxOfferCode = createAsyncThunk('getToolboxOfferCode', async ({ path }: OfferParams) => {
  await getCSRFToken();

  const resp = await fetch(GET_TOOLBOX_OFFER_CODE(path), {
    headers: {
      'login-token': getLoginToken(),
    },
  });

  const data = await resp.text();

  return data;
})
